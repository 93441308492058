import "./utils/index";
import homeSlider from "./modules/slider-home";
import tabs from "./modules/tabs";
import Form from "./modules/form";
import toggleMenu from "./modules/toogle-menu";
import roadmapSlider from "./modules/slider-map";
import teamSlider from "./modules/slider-team";
import accordionList from "./modules/animations-faq";
import designSlider from "./modules/slider-design";
import Maps from "./modules/google-maps";
import { onscrollAnimations } from "./modules/animations-onscroll";
import { onhoverAnimations } from "./modules/animations-onhover";
import { onloadAnimations } from "./modules/animations-onload";
import { careersAjax } from "./modules/careers-ajax";


onloadAnimations();
onscrollAnimations();
onhoverAnimations();
Maps();
designSlider();
accordionList();
teamSlider();
roadmapSlider();
toggleMenu();
Form();
tabs();
homeSlider();
careersAjax();


