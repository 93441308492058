import gsap from "gsap";
import Swiper, { FreeMode } from "swiper";
import { StrokeAnimator } from "../utils/stroke-animator";

export default function roadmapSlider() {
  const section = document.querySelector('.roadmap');
  const slider = document.querySelector(".map_slider");
  if (!slider) return;

  const isMob = window.innerWidth <= 1024;

  function initSlider(s) {

    const swipeButton = section.querySelector('.roadmap-button');
    const buttonStroke = new StrokeAnimator(swipeButton);

    const slider = new Swiper(s, {
      modules: [FreeMode],
      freeMode: !isMob,
      slidesPerView: "auto",
      grabCursor: true,
      on: {
        progress(swiper, progress) {
          buttonStroke.animate(progress * 100, 1);
        }
      }
    });
  }

  initSlider(slider);

  function moveLines() {
    const wrapper = section.querySelector('.lines');
    const svg = wrapper.querySelector('svg');
    const lines = [...svg.querySelectorAll('rect')];

    const animate = (el) => {
      gsap.timeline({ repeat: 1, yoyo: true, delay: "random(0.5, 1)", onComplete: () => animate(el) })
        .to(el, {
          duration: "random(2, 3)",
          y: "random(-80, 80)",
          ease: "none"
        })
    }

    lines.length && lines.forEach(el => animate(el));
  }

  moveLines();
}
