import gsap from "gsap";
import Konva from "konva";

/**
 * Custom helper class that loads 1 or 2 SVG files with rects, that have different coords (transform matrix used) and convert it into canvas objects, and create animations:
 * 1) animate on mousemove (parallax)
 * 2) random vertical animate
 * 3) moving rects from SVG-1 coords to rects SVG-2 coords (if 2 SVGs loaded)
 * NOTE - number of rects must be the same in 1 and 2 SVG
 *
 * TODO:
 * 1. Disable animation when another is running
 * 2. Detect fill type of rects (only linear gradient is supported yet)
 * 3. Remove SVGs from DOM after setup
 */
export class Particles {

  constructor(options) {
    this.container = options?.wrapper ? options.wrapper : document.getElementById('container');
    this.svgSrc = options?.svg ?? [];
    this.groups = [];
    this.particles = [];
    this.endCoords = [];
    this.stageWidth;
    this.stageHeight;
    this.virtualDOM = document.createDocumentFragment();

    this.svgSrc.length && this._loadSvg();
  }

  setup = () => {
    this.startSVG = this.virtualDOM.querySelector('svg.start');
    this.groups = [...this.startSVG.querySelectorAll('g')];
    this.stageWidth = this.startSVG.width.baseVal.value;
    this.stageHeight = this.startSVG.height.baseVal.value;

    if (this.svgSrc.length > 1) {
      this.endSVG = this.virtualDOM.querySelector('svg.end');
      this.endRects = [...this.endSVG.querySelectorAll('rect')];
      this.endRects.forEach((rect, idx) => {
        this.endCoords[idx] = this._getCoords(rect);
      })
    }

    this.stage = new Konva.Stage({
      container: this.container,
      width: this.stageWidth,
      height: this.stageHeight,
    });

    this.layer = new Konva.Layer({
      listening: false
    });

    this.stage.add(this.layer);

    this.scaleStage();
    window.addEventListener('resize', () => this.scaleStage());

    this.groups.length && this.groups.forEach((g, idx) => {

      const group = new Konva.Group({
        listening: false
      });

      this.layer.add(group);

      const rects = [...g.querySelectorAll('rect')];

      rects.length && rects.forEach(el => {
        const rect = this._createRect(el);
        group.add(rect);
        this.particles.push(rect);
      });

      this.groups[idx] = group;

    })

    // Create animations
    this.parallaxAnimation.create();
    this.randomAnimation.create();
    this.svgSrc.length > 1 && this.expandAnimation.create();
  }

  scaleStage() {
    this.scale = this.container.clientWidth / this.stageWidth;
    this.stage.width(this.stageWidth * this.scale);
    this.stage.height(this.stageHeight * this.scale);
    this.stage.scale({ x: this.scale, y: this.scale });
    this.stage.draw();
  }

  expandAnimation = {
    expanded: false,
    tweens: [],
    create: () => {
      this.particles.forEach((p, idx) => {

        let tween = new Konva.Tween({
          node: p,
          duration: 2.5,
          x: this.endCoords[idx].x,
          y: this.endCoords[idx].y,
          width: this.endCoords[idx].width,
          height: this.endCoords[idx].height,
          easing: Konva.Easings.EaseInOut
        });

        this.expandAnimation.tweens.push(tween);
      })
    },

    toggle: () => {
      this.expandAnimation.tweens.forEach(animate => {
        !this.expandAnimation.expanded ? animate.play() : animate.reverse();
      });
      this.expandAnimation.expanded = !this.expandAnimation.expanded;
    },

    play: () => {
      this.expandAnimation.tweens.forEach(animate => {
        animate.play();
      });
      this.expandAnimation.expanded = true;
    },

    reverse: () => {
      this.expandAnimation.tweens.forEach(animate => {
        animate.reverse();
      });
      this.expandAnimation.expanded = false;
    }
  }

  parallaxAnimation = {
    animation: null,
    mouse: {
      x: 0,
      y: 0
    },
    create: () => {

      let centerX = window.innerWidth / 2;
      let centerY = window.innerHeight / 2;

      const frameUpdate = (frame) => {

        this.groups.forEach((layer, idx) => {
          let x = (this.parallaxAnimation.mouse.x - centerX) * `-0.0${1 + idx}` * 0.5;
          let y = (this.parallaxAnimation.mouse.y - centerY) * `-0.0${1 + idx}` * 0.5;

          layer.x(x);
          layer.y(y);
        });
      }

      this.parallaxAnimation.animation = new Konva.Animation(frameUpdate, this.stage);
      this.parallaxAnimation.play();
    },

    updatePointer: (e) => {
      this.parallaxAnimation.mouse.x = e.pageX;
      this.parallaxAnimation.mouse.y = e.pageY;
    },

    play: () => {
      window.addEventListener('mousemove', this.parallaxAnimation.updatePointer);
      this.parallaxAnimation.animation.start();
    },

    pause: () => {
      window.removeEventListener('mousemove', this.parallaxAnimation.updatePointer);
      this.parallaxAnimation.animation.stop();
    }
  }

  randomAnimation = {
    create: () => {

      const amplitude = 25;

      const rand = (el) => {

        const min = Math.max(0, (el.y() - amplitude));
        const max = Math.min((el.y() + amplitude), (this.stage.height() - el.height()));
        const calc = Math.random() * (max - min) + min;

        return calc;
      }

      const animate = (el, idx) => {

        gsap.timeline({ repeat: 1, yoyo: true, delay: "random(0, 3)", onComplete: () => animate(el, idx) })
          .to(el, {
            duration: "random(2, 4)",
            y: () => rand(el),
            ease: "none"
          })
      }

      this.particles.forEach((el, idx) => animate(el));
    },

    play: () => {

    },

    pause: () => {

    }


  }

  /** Helpers */

  _loadSvg = () => {

    Promise.all(this.svgSrc.map(u => fetch(u)))
      .then(responses =>
        Promise.all(responses.map(res => res.text()))
      )
      .then(svg => {
        let div = document.createElement('div');
        div.insertAdjacentHTML('beforeEnd', svg);
        this.virtualDOM.append(div);
      })
      .then(this.setup);
  }

  _createRect(el) {
    const { x, y, width, height } = this._getCoords(el);
    const fill = this._getFill(el);

    const rect = new Konva.Rect({
      listening: false,
      width: width,
      height: height,
      x: x,
      y: y,
      fillPriority: fill.fillPriority,
      fillLinearGradientStartPoint: { x: fill.x1, y: fill.y1 },
      fillLinearGradientEndPoint: { x: fill.x2, y: fill.y2 },
      fillLinearGradientColorStops: fill.colorStops
    })

    return rect;
  }

  _getFill(el) {

    let fillID = el.getAttribute('fill');
    if (!fillID) return null;

    let fillSrc = this.startSVG.querySelector(`defs #${fillID.slice(5, -1)}`);

    let fill = {
      fillPriority: 'linear-gradient',
      x1: parseInt(fillSrc.getAttribute('x1')),
      x2: parseInt(fillSrc.getAttribute('x2')),
      y1: parseInt(fillSrc.getAttribute('y1')),
      y2: parseInt(fillSrc.getAttribute('y2')),
      colorStops: []
    };

    let stopOffsets = fillSrc.querySelectorAll('stop');

    stopOffsets.length && stopOffsets.forEach(stop => {
      let offset = stop.getAttribute('offset') ?? 0;
      let stopColor = stop.getAttribute('stop-color') ?? 0;
      let stopOpacity = stop.getAttribute('stop-opacity') ?? 1;
      fill.colorStops.push(offset, this._convertHexToRGBA(stopColor, stopOpacity));
    })

    return fill;
  }

  _getCoords(el) {

    let x, y;

    const height = parseInt(el.getAttribute('height'));
    const width = parseInt(el.getAttribute('width'));

    if (el.getAttribute('x') && el.getAttribute('x')) {
      x = parseInt(el.getAttribute('x'));
      y = parseInt(el.getAttribute('y'));
    } else {

      const matrix = el.getAttribute('transform');
      const matrixValues = matrix.split(' ');

      x = parseInt(matrixValues[4]);
      y = parseInt(matrixValues[5]) - height;
    }

    return { x, y, width, height }
  }

  _convertHexToRGBA(hexCode, opacity = 1) {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  };
}


