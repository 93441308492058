import { Loader, LoaderOptions } from "google-maps";
import Swiper, { FreeMode } from "swiper";

export default function Maps() {
  const g = document.getElementById("googleMaps");
  if (!g) return;

  const isMob = window.innerWidth <= 1024;
  const maps = g.querySelectorAll(".g_map");
  const loader = new Loader(g.dataset.key);
  let mapStyle;

  fetchMapStyle();

  async function fetchMapStyle() {

    let response = await fetch(`${BAMBOO.assets}img/map/map-style.json`, {
      method: "GET",
      credentials: 'same-origin',
    });

    if (response.ok) {
      mapStyle = await response.json();
      initMaps();
    } else {
      console.log(response.status);
    }
  }

  function initSlider(s) {
    // console.log("init");
    const slider = new Swiper(s, {
      modules: [FreeMode],
      freeMode: !isMob,
      slidesPerView: "auto",
      grabCursor: !isMob,
    });
    // slider.disable();

    !isMob &&
      maps.forEach((map) => {
        map.addEventListener("mouseenter", () => slider.disable());
        map.addEventListener("mouseleave", () => slider.enable());
      });
  }

  function initMaps() {
    maps.forEach((map) => initMap(map));
    (maps.length > 4 || isMob) && initSlider(g);
  }

  function initMap(m) {
    loader.load().then(function (google) {
      const map = new google.maps.Map(m, {
        center: {
          lat: Number(m.dataset.lat),
          lng: Number(m.dataset.lng)
        },
        zoom: Number(m.dataset.zoom),
        disableDefaultUI: true,
        gestureHandling: "greedy",
        mapTypeControl: false,
        styles: mapStyle
      });
    });
  };
}
