import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { scrollBar } from "./scroll-bar";

// Main scrollbar
const mainScrollBar = new scrollBar({
  // draggable: true,
  smoothScroll: {
    speed: 250,
    smooth: 15
  },
  isMobile: 1024
});

// Disable / enable scroll
const stopScroll = () => {
  disableBodyScroll(document.body);
  mainScrollBar.lockScroll();
};

const startScroll = () => {
  enableBodyScroll(document.body);
  mainScrollBar.unlockScroll();
};

export { mainScrollBar, stopScroll, startScroll };
