import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
import { onhoverAnimations } from "./animations-onhover";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const careersAjax = () => {

  const careers = document.querySelector('section.opportunities');
  if (!careers) return;

  const tabs = [...careers.querySelectorAll('.nav button')];
  const container = careers.querySelector('[data-posts-container]');
  const loadMoreButton = careers.querySelector('.load_more');

  let paged = 1;
  let cat = '';
  let isFetching;

  const toggleActiveTab = (active) => {
    tabs.forEach((tab, idx) => {
      tab.classList.toggle('active', idx === active);
    });
  }

  tabs.length && tabs.forEach((tab, idx) => {

    tab.addEventListener('click', () => {
      paged = 1;
      cat = tab.dataset.cat;

      gsap.to(window, { duration: 0.5, scrollTo: careers, onComplete: loadPosts });
      toggleActiveTab(idx);
    });
  });

  careers.addEventListener('click', loadMorePosts);

  function loadPosts() {

    isFetching = true;

    let slides = container.querySelectorAll('.swiper-slide');

    // Reload posts
    const insertPostsToContainer = (html) => {

      container.innerHTML = html;
      let newSlides = container.querySelectorAll('.swiper-slide');

      gsap.timeline({
        onComplete() {
          isFetching = false;
        }
      })
        .set(newSlides, { autoAlpha: 0 })
        .set(container, { autoAlpha: 1 })
        .to(newSlides, { duration: 0.3, autoAlpha: 1, stagger: 0.05 });
    }

    // Append posts
    const appendPostsToContainer = (html) => {

      let tpl = document.createDocumentFragment();
      let div = document.createElement('div');
      let posts;

      div.innerHTML = html;
      tpl.appendChild(div);

      posts = [...tpl.querySelectorAll('.swiper-slide')];
      tpl = null;

      if (posts) {
        posts.forEach(post => {
          post.classList.add('loading');
          container.appendChild(post);
        });

        gsap.to(posts, {duration: 0.75, autoAlpha: 1, stagger: 0.2, onComplete(){
          posts.forEach(p => p.classList.remove('loading'));
        }})
      }
    }

    // Send request
    const sendAJAX = async () => {

      let data = new FormData();
      data.append('action', 'getVacanciesAJAX');
      data.append('cat_id', cat);
      data.append('paged', paged);

      let response = await fetch(BAMBOO.ajaxUrl, {
        method: "POST",
        credentials: 'same-origin',
        body: data
      });

      // Get response
      if (response.ok) {

        let posts = await response.text();

        if (paged === 1) {
          insertPostsToContainer(posts);
        } else {
          appendPostsToContainer(posts);
        }

        onhoverAnimations();
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 200);

        // Hide loadmore button on last post
        loadMoreButton && loadMoreButton.classList.toggle('hidden', container.querySelector('[data-last-post="true"]'));

      } else {
        console.log(response.status);
        isFetching = false;
      }
    }

    if (paged === 1) {
      gsap.timeline({ onComplete: sendAJAX })
        .to(slides, { duration: 0.3, autoAlpha: 0, stagger: { each: 0.05, from: "end" } })
        .set(container, { autoAlpha: 0 });
    } else {
      sendAJAX();
    }
  }

  function loadMorePosts(e) {
    if (e.originalTarget !== loadMoreButton) return;
    paged++;
    loadPosts();
  }

}

export { careersAjax }