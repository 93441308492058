import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import Splitting from "splitting";
import { _debounce } from "../utils";
import { animationsHomePage } from "./animations-homepage";
import { revealAnimations } from "./animations-reveal";

const onscrollAnimations = () => {

  let mm = gsap.matchMedia();

  const sections = [...document.querySelectorAll('section')];
  const invertedSections = sections.filter(s => s.dataset.inverted);

  const welcomeHome = document.querySelector('.home .welcome');
  const lastSection = (sections.length > 1) ? sections[sections.length - 1] : sections[0];

  const header = document.querySelector('header');
  const headerOffset = header.clientHeight / 2;

  const aside = document.querySelector('aside .wrapper');
  const asideNum = aside.querySelector('.active');
  const asideAllNums = aside.querySelector('.all');
  const asideOffset = aside.getBoundingClientRect();

  const main = document.querySelector('main');
  const footer = document.querySelector('footer');
  const scrollUp = document.querySelector('.up_scroll');

  const pinned = [...document.querySelectorAll('[data-pinned]')];

  // Invert header on scroll
  const toggleHeader = (section) => {

    ScrollTrigger.create({
      trigger: section,
      start: `top ${headerOffset}px`,
      end: `bottom ${headerOffset}px`,
      onToggle: (e) => header.classList.toggle('inverted', e.isActive)
    });
  }

  // Invert & change section number in aside navigation
  const toggleHeaderAside = () => {

    mm.add("(min-width: 1024px)", () => {

      let activeIdx = 0;
      let currentIdx = 0;
      let visibleIdx;
      let oneSection = sections.length < 2;

      // Hide navigation if only 1 section
      oneSection && aside.classList.add('hidden');
      asideAllNums.textContent = (sections.length < 10) ? `0${sections.length}` : sections.length;;

      const toggleActiveIdx = () => {

        if (currentIdx === activeIdx) return;

        visibleIdx = (activeIdx < 9) ? `0${activeIdx + 1}` : activeIdx + 1;

        asideNum.textContent = visibleIdx;
        currentIdx = activeIdx;
      }

      sections.forEach((s, idx) => {

        ScrollTrigger.create({
          trigger: s,
          start: `top ${asideOffset.top}px`,
          end: `bottom ${asideOffset.bottom}px`,
          onToggle(e) {
            if (e.isActive) {
              aside.classList.toggle('inverted', s.dataset.inverted === 'true');
              activeIdx = idx;
              toggleActiveIdx();
            }
          }
        });
      });

      const hideHeader = gsap.timeline({ paused: true })
        .to(header, { duration: 0.6, yPercent: -100, autoAlpha: 0, ease: "none" });

      // Hide aside navigation & header on footer
      ScrollTrigger.create({
        trigger: lastSection,
        start: `bottom ${asideOffset.top}px`,
        onToggle(e) {
          !oneSection && aside.classList.toggle("hidden", e.isActive);
          (e.isActive) ? hideHeader.play() : hideHeader.reverse();
        },
      });

      // return () => {};
    });
  }

  // Footer animation
  const revealFooter = () => {

    mm.add("(min-width: 1024px)", () => {

      const footerContent = footer.querySelector('.footer-content');

      const updateBottomMargin = () => {
        main.style.marginBottom = `${footer.scrollHeight}px`;
        ScrollTrigger.refresh();
      }

      const resizeObserver = new ResizeObserver(entries => updateBottomMargin());
      resizeObserver.observe(footer);

      gsap.timeline({
        scrollTrigger: {
          trigger: lastSection,
          scrub: true,
          // markers: true,
          start: "bottom bottom",
          end: () => `+=${footer.scrollHeight}`,
        }
      })
        .to(footer, { y: 0, ease: "none" }, "footer")
        .to(footerContent, { autoAlpha: 1, ease: "none" }, "footer");

      return () => {
        resizeObserver.unobserve(footer);
        main.style.marginBottom = '0px';
      }
    })
  }

  // Scroll to top of document
  const scrollToTop = () => {

    mm.add("(min-width: 1024px)", (context) => {

      context.add("onClick", () => {
        gsap.to(window, { duration: 1.5, scrollTo: 0, ease: "power1.inOut" });
      });

      scrollUp.addEventListener("click", context.onClick);

      return () => {
        scrollUp.removeEventListener("click", context.onClick);
      };
    });
  }

  // Pin elements
  const pinElement = (el) => {

    mm.add("(min-width: 1024px)", () => {

      let parent = el.closest('section');

      const offsetTop = (el.dataset.pinned !== '')
        ? el.dataset.pinned
        : el.getBoundingClientRect().top - parent.getBoundingClientRect().top;

      ScrollTrigger.create({
        trigger: el,
        pin: true,
        start: `top ${offsetTop}px`,
        endTrigger: parent,
        end: `bottom bottom`,
        // markers: true,
        pinSpacing: false
      });
    });
  }

  // Run animations
  welcomeHome && animationsHomePage(sections);
  invertedSections.length && invertedSections.forEach(s => toggleHeader(s));
  pinned.length && pinned.forEach(el => pinElement(el));

  footer && revealFooter();
  scrollUp && scrollToTop();
  toggleHeaderAside();
  revealAnimations();
}

export { onscrollAnimations }