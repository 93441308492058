import gsap from "gsap";
import Splitting from "splitting";
import { mainScrollBar, startScroll, stopScroll } from "../utils/scroll-config";
import { Particles } from "../utils/svg2canvas-animator";

function onloadAnimations() {

  const preloader = document.getElementById('preLoader');
  const isIOS = document.querySelector('html').classList.contains('ios');
  document.body.classList.add('loading');

  let timescale = 1;
  let hideDelay;

  let gmm = gsap.matchMedia();

  function showPreloader() {

    if (!sessionStorage.isVisited) {
      sessionStorage.isVisited = 'true';
      timescale = 1;
      hideDelay = 2;
    } else {
      timescale = 2;
      hideDelay = 1.5;
    }

    const logo = preloader.querySelector('.preloader-logo');
    const svg = logo.querySelector('svg');

    const logoSymbol = svg.querySelector('.logo-symbol');
    const symbolParts = logoSymbol.querySelectorAll('path');

    const smallClip = svg.querySelector('#smallClip');
    const smallClipPath = smallClip.querySelector('path');
    const largeClip = svg.querySelector('#largeClip');
    const largeClipPath = largeClip.querySelector('path');

    const largeGroup = svg.querySelector('.large-group > g');
    const smallGroup = svg.querySelector('.small-group > g');

    let rotatePreloader = gsap.timeline({ repeat: -1, paused: true });
    if (!isIOS) {
      rotatePreloader.to([largeGroup, smallGroup], { duration: 100, rotation: 360, ease: "none" }, "rotate")
    } else {
      rotatePreloader.to([largeGroup, smallGroup], { duration: 5, scale: 1.2 }, "rotate")
    }

    let animatePreloader = gsap.timeline({
      paused: true, onComplete: () => {
        animatePreloader.kill();
        animatePreloader = null;
        rotatePreloader.kill();
        rotatePreloader = null;
        showLayout();
      }
    })
      .to(symbolParts, {
        autoAlpha: 1, ease: "steps(1)", stagger: {
          each: 0.25, from: "end"
        }
      }, "reveal")
      .from([smallClipPath, largeClipPath], { duration: 2, scale: 0, autoAlpha: 0 }, "reveal+=0.5")
      .call(() => {
        rotatePreloader.timeScale(timescale).play()
      }, null, "reveal+=0.5")
      .to(logo, { duration: 0.3, delay: hideDelay, autoAlpha: 0, ease: "none" }, "hide")
    // .to(preloader, { duration: 1, autoAlpha: 0, ease: "none" })

    animatePreloader.timeScale(timescale).play();
  }

  function showLayout() {
    const header = document.querySelector('header');
    const main = document.querySelector('main');
    const aside = document.querySelector('aside');

    const welcome = document.querySelector('section.welcome');
    const title = welcome?.querySelector('h1');
    let results;
    let titleChars;

    let animateWelcome = gsap.timeline({ paused: true });

    gmm.add('(min-width: 1025px)', () => {

      results = title ? Splitting({ target: title, by: 'chars' }) : null;
      titleChars = title?.querySelectorAll('.char');

      if (welcome && titleChars.length) {

        animateWelcome.from([titleChars], {
          duration: 1, yPercent: 100, autoAlpha: 0, ease: "power2.out", immediateRender: true
        }, "load");
      }
    });



    let layoutAnimate = gsap.timeline({
      paused: true,
      onComplete: () => {
        document.body.classList.remove('loading');
        startScroll();
        preloader && preloader.remove();
        layoutAnimate.kill();
        layoutAnimate = null;
      }
    })
      .to([header, aside], { duration: 1, autoAlpha: 1, ease: "none", stagger: 0.25 }, "layout")
      .to(preloader, { duration: 1, autoAlpha: 0 }, "layout")
      .call(() => { animateWelcome.play() }, null, "layout");

    layoutAnimate.timeScale(timescale).play();
  }

  if (preloader) {
    stopScroll();
    showPreloader();
  } else {
    showLayout();
  }



  function particlesAnimate() {
    const aboutParticles = document.querySelector('.welcome.about .particles');

    aboutParticles && new Particles({
      wrapper: aboutParticles,
      svg: [
        `${BAMBOO.assets}img/particles/about-particles.svg`,
      ]
    });

    const careersParticles = document.querySelector('.welcome.careers .particles');

    careersParticles && new Particles({
      wrapper: careersParticles,
      svg: [
        `${BAMBOO.assets}img/particles/careers-particles.svg`,
      ]
    });

    const worksParticles = document.querySelector('.welcome.works .particles');

    worksParticles && new Particles({
      wrapper: worksParticles,
      svg: [
        `${BAMBOO.assets}img/particles/ourwork-particles.svg`,
      ]
    });

    const contactsParticles = document.querySelector('.welcome.contact .particles');

    contactsParticles && new Particles({
      wrapper: contactsParticles,
      svg: [
        `${BAMBOO.assets}img/particles/contacts-particles.svg`,
      ]
    });
  }

  gsap.matchMedia().add('(min-width: 1024px)', () => {
    particlesAnimate();
  });
}

export { onloadAnimations }