import autosize from "autosize";
import { startScroll, stopScroll } from "../utils/scroll-config";

export default function Form() {
  const content = document.querySelectorAll(".form_content");
  if (!content.length) return;

  const successPopUp = document.querySelector(".pop-up.f_pop");
  const vacancyPopUp = document.querySelector(".pop-up.v_pop");

  const closePopUp = document.querySelectorAll(".close_btn");

  const textarea = [...document.querySelectorAll("textarea")];

  autosize(textarea);

  // Form functions
  function useForm(c) {
    let formState = {};
    const form = c.querySelector("form");
    const method = form.method;
    const inputs = [...form.elements].filter(
      (i) => i.type !== "submit" && i.type !== "hidden"
    );
    const fileInput = inputs.filter((i) => i.type == "file")[0];

    const submit = c.querySelector("button.submit");
    const labels = inputs.map((i) => i.closest("label")).filter((i) => i);

    let file,
      size,
      cont,
      addFile,
      types,
      max_size,
      fileInfo,
      fileName,
      fileRemove,
      accept;

    if (fileInput) {
      cont = c.querySelector(".bottom_nav .file");
      addFile = cont.querySelector(".add_file_btn");
      types = [];
      max_size = fileInput.getAttribute("size") / 1024 / 1024;
      fileInfo = cont.querySelector(".file-info");
      fileName = fileInfo.querySelector(".file-name");
      fileRemove = fileInfo.querySelector(".file-remove");
      accept = fileInput.getAttribute("accept");

      // fileInput.setAttribute("multiple", "multiple");
      accept && fileInput.setAttribute("accept", accept.split("|").join(","));

      // fileRemove.addEventListener("click", removeFile);
      fileInput.addEventListener("change", addFileName);
      addFile.addEventListener("click", (e) => {
        if (fileInfo.querySelector("span"))
          fileInfo.querySelector("span").remove();
        removeFile();
        fileInput.click();
      });
    }

    function removeFile() {
      // e.preventDefault();
      fileName.textContent = "";
      cont.classList.remove("visible", "all");
      fileInput.value = "";
    }

    function addFileName(e) {
      let files = Array.from(fileInput.files);

      // Max files error
      if (files.length > 3) {
        errorFile("Max 3 files");
        return;
      }

      const dt = new DataTransfer();

      cont.classList.toggle("all", fileInput.files.length == 3);

      files.forEach((file, idx) => {
        dt.items.add(file);
        let i = file.name.split(".");
        let f = document.createElement("div");
        f.classList.add("file");

        f.innerHTML = `<div class="name">${i[0]}</div><div class="type">.${i[1]}</div><div class="remove"></div>`;

        f.lastElementChild.addEventListener("click", (e) => {
          [...dt.items].forEach((item, i) => {
            if (item.getAsFile().name == file.name) {
              dt.items.remove(i);
              fileInput.files = dt.files;
              f.remove();
            }
          });
          cont.classList.toggle("all", fileInput.files.length == 3);
        });

        // f.append(n, r);
        fileName.appendChild(f);
      });

      // size = fileInput.files[0].size / 1024 / 1024;

      // let ext = "." + file.split(".").pop();

      if (fileInfo.querySelector("span"))
        fileInfo.querySelector("span").remove();

      // Size error
      // if (size.toFixed(2) > max_size) {
      //   errorFile(`Max file size ${max_size.toFixed()} MB`);
      // }

      // Extension error
      // if (!types.includes(ext)) {
      //   errorFile("This type of file is not allowed");
      //   return;
      // }
      cont.classList.add("visible");
    }

    function errorFile(msg) {
      if (fileInfo.querySelector("span"))
        fileInfo.querySelector("span").remove();
      let s = document.createElement("span");
      s.classList.add("file-error");
      s.textContent = msg;

      fileInfo.appendChild(s);
      setTimeout(removeFile, 10);
    }

    // Before request
    const beforeSend = () => {
      // Remove class 'error' from label
      labels.forEach((label) => {
        // let t = label.querySelector(".tip");
        // t && (t.innerHTML = "");
        label.classList.remove("error");
      });

      // Lock submit button to prevent multiple clicks
      submit.disabled = true;
    };

    // After successful request
    const onSuccess = (message, title) => {
      //Show message
      successPopUp.querySelector(".title").innerHTML = title;
      successPopUp.querySelector(".message").innerHTML = message;

      //Open pop up & Disable Body scroll
      showPopUp();

      // Reset form
      inputs.forEach((input) => {
        input.closest("label").classList.remove("active");

        if (input.type == "checkbox") {
          input.checked = false;
          return;
        }
        input.value = "";
        input.setAttribute("value", "");
      });

      //Hide file info
      fileInput && removeFile();

      // Update autosize
      setTimeout((e) => autosize.update(textarea));
    };

    // Form validation error
    const onValidateError = (errors) => {
      // submit.disabled = false;

      errors.forEach((error) => {
        let input = inputs.filter((i) => i.id === error.idref);

        if (input[0].type == "file") {
          removeFile();
          errorFile(error.message);
          return;
        }

        let label = input[0].closest("label");
        let t = label.querySelector(".tip");
        label && label.classList.add("error");
        t && (t.innerHTML = error.message);
      });
    };

    // Server error
    const onServerError = (data) => {
      onSuccess(data.message, "Error");
      successPopUp.classList.add("error");
    };

    // Send form via AJAX
    const sendForm = () => {
      // Before request send
      beforeSend();

      if (fileInput && !fileInput.files.length) {
        errorFile("You need add file");
        submit.disabled = false;
        return;
      }

      // Form data
      let data = new FormData(form);
      let url = `${BAMBOO.baseUrl}/wp-json/contact-form-7/v1/contact-forms/${form.id}/feedback`;
      data.append("action", "submit_ccform");

      // Send request
      fetch(url, {
        method: method,
        credentials: "same-origin",
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          submit.disabled = false;
          if (data) {
            // On validate
            switch (data.status) {
              case "validation_failed":
                onValidateError(data.invalid_fields);
                break;
              case "mail_sent":
                onSuccess(data.message, "Success");
                break;
              case "mail_error":
                onServerError(data);
                break;
              default:
                console.log("default");
            }
          }
        })
        // Fetch error
        .catch((err) => {
          onServerError(err);
        });
    };

    const innerTextToInput = (label, value) => {
      let input = label.querySelector(".hidden");
      input.value = value ? value : "";
    };

    inputs.forEach((input) =>
      input.addEventListener("input", () => {
        if (input.type == "file") return;
        let label = input.closest("label");
        let err = label.classList.contains("error");
        let v = input.value;
        let name = input.name
          ? input.name
          : label.querySelector("input[name]").name;

        formState = {
          ...formState,
          [name]: v,
        };
        input.classList.contains("pseudo") && innerTextToInput(label, v);

        if (err) {
          // let t = label.querySelector(".tip");
          // t && (t.innerHTML = "");
          label.classList.remove("error");
        }

        label.classList.toggle("active", v);
      })
    );
    // Submit form on click
    // form.addEventListener("submit", (e) => {
    //   e.preventDefault();
    //   sendForm();
    // });

    submit.addEventListener("click", sendForm);
  }

  function showPopUp() {
    stopScroll();
    successPopUp.classList.add("active");
    document.addEventListener("keydown", hideOnEscape);
    document.addEventListener("click", hideOnOutside);
  }

  function hidePopUp() {
    startScroll();
    successPopUp.classList.remove("active");
    vacancyPopUp && vacancyPopUp.classList.remove("active");
    document.removeEventListener("keydown", hideOnEscape);
    document.removeEventListener("click", hideOnOutside);

    setTimeout(() => {
      successPopUp.classList.remove("error");
    }, 400);
  }

  // Close PopUp on Escape keydown
  function hideOnEscape(e) {
    if (e.key === "Escape") hidePopUp();
  }

  // Close PopUp on click outside Popup
  function hideOnOutside(e) {
    if (e.target.id == "pop-up") hidePopUp();
  }

  // pop up for vacancy
  function initVacancy() {
    const open = document.querySelector(".apply_btn");

    open.addEventListener("click", (e) => {
      stopScroll();
      vacancyPopUp.classList.add("active");
      document.addEventListener("keydown", hideOnEscape);
    });
  }

  [...content].forEach((c) => useForm(c));
  [...closePopUp].forEach((b) => b.addEventListener("click", hidePopUp));

  vacancyPopUp && initVacancy();
}
