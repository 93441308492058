import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import Splitting from "splitting";

const revealAnimations = () => {

  const fades = [...document.querySelectorAll('[data-fade]')];
  const staggers = [...document.querySelectorAll('[data-stagger]')];
  const scrollParallaxItems = [...document.querySelectorAll('[data-parallax-scroll]')];
  const imgRevealed = [...document.querySelectorAll('[data-reveal-img]')];
  const textRevealed = [...document.querySelectorAll('[data-reveal-text]')];
  const svgFadeIns = [...document.querySelectorAll('[data-svg-fadein]')];

  const revealText = (text) => {
    const split = Splitting({ target: text, by: 'chars' });
    const chars = text.querySelectorAll('.char');
    const type = text.dataset.revealText;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: text,
        scrub: 1,
        // markers: true,
        end: "top 60%"
        // start: "top 5%",
      }
    });

    if (type === 'chars') {
      tl.from([chars], {
        autoAlpha: 0, stagger: 0.05
      }, "scroll")
    }

    if (type === 'lines') {
      tl.from([chars], {
        yPercent: 100, autoAlpha: 0, stagger: 0.05
      }, "scroll")
    }


  }

  const animateFades = (el) => {

    let type = el.dataset.fade;

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        toggleActions: "play none none reverse",
        start: "top 80%",
        // markers: true
      }
    });

    switch (type) {
      case 'in':
        tl.from(el, { duration: 2, autoAlpha: 0, ease: "none" });
        break;
      case 'up':
        tl.from(el, { duration: 1.5, y: 100, autoAlpha: 0, ease: "none" });
        break;
      default:
        break;
    }
  }

  const revealImage = (el) => {

    let param = "height";
    // let param = (el.dataset.revealImg !== '') ? el.dataset.revealImg : "height";

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        scrub: 0.2,
        // markers: true,
        start: "top bottom+=15%",
        end: "top 20%"
      }
    });

    tl.from(el.children[0], { [param]: 0, ease: "none" })
  }

  const animateStaggers = (el) => {

    let items = [...el.querySelectorAll('[data-item]')];
    if (!items.length) return;

    let type = el.dataset.stagger;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        toggleActions: "play none none reverse",
        // markers: true
      }
    });

    switch (type) {
      case 'fadein':
        tl.from([items], { duration: 1, autoAlpha: 0, ease: "none", stagger: 0.2 });
        break;
      case 'fadeup':
        tl.from([items], { duration: 1, autoAlpha: 0, yPercent: 50, stagger: 0.3 });
        break;
      default:
        break;
    }
  }

  const parallaxAnimation = (el) => {

    let options = JSON.parse(el.dataset.parallaxScroll);

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        scrub: 1,
        // markers: true
      }
    });

    tl.to(el, { yPercent: options?.yPercent ?? 0, y: options?.y ?? 0 })
  }

  const fadeInSvgOnScroll = (wrapper) => {

    const svg = wrapper.querySelector('svg');
    let pathes = [...svg.querySelectorAll('.group-mask path')];

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper
      }
    });

    tl
      .from([pathes], { autoAlpha: 0, stagger: { each: 0.03, from: "random" } })
  }


  gsap.matchMedia().add('(min-width: 1024px)', () => {
    fades.length && fades.forEach(el => animateFades(el));
    staggers.length && staggers.forEach(el => animateStaggers(el));
    scrollParallaxItems.length && scrollParallaxItems.forEach(el => parallaxAnimation(el));

    imgRevealed.length && imgRevealed.forEach(el => revealImage(el));
    textRevealed.length && textRevealed.forEach(text => revealText(text));
    svgFadeIns.length && svgFadeIns.forEach(el => fadeInSvgOnScroll(el));
  });


}

export { revealAnimations }