import gsap from "gsap";

export default function tabs() {
  const cont = document.querySelector(".impact");
  if (!cont) return;

  const tabs = cont.querySelectorAll(".tabs .tab");
  // const dots = cont.querySelectorAll(".dots .dot");
  const colums = cont.querySelectorAll(".descriptions .column");
  const svg = cont.querySelector('.image .icon svg');

  const dots = [...svg.querySelectorAll('.dot')];

  dots.sort(function (a, b) {
    if (a.dataset.order < b.dataset.order) {
      return -1;
    }
    if (a.dataset.order > b.dataset.order) {
      return 1;
    }
    return 0;
  });

  const onClick = (elem, idx) => {
    elem.addEventListener("click", (e) => {
      tabs.forEach((t, i) => t.classList.toggle("active", i == idx));
      dots.forEach((t, i) => t.classList.toggle("active", i == idx));
      colums.forEach((c, i) => c.classList.toggle("active", i == idx));

      gsap.to(window, { duration: 0.6, delay: 0.3, scrollTo: { y: '.column.active', offsetY: 100 } });
    });
  };

  tabs.forEach((d, i) => onClick(d, i));
  dots.forEach((d, i) => onClick(d, i));

  // tabs[0].click();
  tabs[0].classList.add('active');
  dots[0].classList.add('active');
  colums[0].classList.add('active');
}
