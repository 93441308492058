import { startScroll, stopScroll } from "../utils/scroll-config";

export default function toggleMenu() {
  const header = document.querySelector("header");
  const burger = header.querySelector(".mobile_btn");

  const openMenu = () => {
    stopScroll();
    header.classList.add("active");
  };
  const closeMenu = () => {
    startScroll();
    header.classList.remove("active");
  };

  burger.addEventListener("click", () => {
    !header.classList.contains("active") ? openMenu() : closeMenu();
  });
}
