import { gsap, ScrollTrigger, ScrollToPlugin, Observer } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Observer);
import Splitting from "splitting";
import { mainScrollBar, startScroll, stopScroll } from "../utils/scroll-config";
import { StrokeAnimator } from "../utils/stroke-animator";
import { Particles } from "../utils/svg2canvas-animator";

const animationsHomePage = (sections) => {

  const welcomeSection = sections[0];
  const sliderSection = sections[1];
  const sliderSectionOffset = sliderSection.offsetTop;
  const normalSection = sections[2];

  const welcomeBg = welcomeSection.querySelector('.welcome-bg');
  const welcomeHero = welcomeSection.querySelector('.welcome-hero');
  const crystal = welcomeHero.querySelector('.crystal');
  const beem = welcomeHero.querySelector('.beem');
  const man = welcomeHero.querySelector('.man');

  const title = welcomeSection.querySelector('h1');
  const results = Splitting({ target: title, by: 'chars' });
  const titleChars = title.querySelectorAll('.char');
  const button = welcomeSection.querySelector('.button');

  // const particles = welcomeSection.querySelector('.particles');
  const particlesWrapper = welcomeSection.querySelector('.particles-wrapper');
  const particles = particlesWrapper.querySelector('.particles');

  const slider = welcomeSection.querySelector('.motto_slider');
  const slidesWrapper = slider.querySelector('.slides-wrapper');
  const slides = [...slidesWrapper.querySelectorAll('.slide')];

  const slideWidth = slides[0].offsetWidth + parseInt(window.getComputedStyle(slides[0]).marginRight);
  const maxDistance = (slideWidth * slides.length) - slidesWrapper.clientWidth;

  const swipeButton = slider.querySelector('.swipe-button');
  const buttonStroke = new StrokeAnimator(swipeButton);

  let slidesOffset = 0;
  let slidesProgress = 0;
  let isSwitching = false;
  let activeIdx = 0;
  let sectionsObserver = null;

  // Scroll to target section
  // const scrollToSection = (target, idx) => {

  //   isSwitching = true;

  //   gsap.to(window, {
  //     duration: 1.25,
  //     ease: "circ.out",
  //     scrollTo: target,
  //     onComplete: () => {
  //       if (!swipeSlider.show.isActive() && !swipeSlider.hide.isActive()) {
  //         isSwitching = false;
  //       }
  //       activeIdx = idx;
  //     }
  //   })
  // }

  // scrollToSection(0);


  gsap.matchMedia()
    .add("(min-width: 1024px)", () => {
      desktopScroll();
    })
    .add("(max-width: 1024px)", () => {
      mobileScroll();
    });

  function mobileScroll() {

    // Slider controller
    const swipeSlider = {
      start: () => {
        swipeSlider.observer.enable();
        swipeSlider.show.play().restart();
      },

      stop() {
        swipeSlider.observer.disable();
        swipeSlider.hide.play().restart();
        swipeSlider.moveSlides(null);
      },

      pause() {
        swipeSlider.observer.disable();
      },

      play() {
        swipeSlider.observer.enable();
      },

      show: gsap.timeline({
        paused: true,
        defaults: { ease: "sine.inOut" },
        onComplete: () => {
          startScroll();
          isSwitching = false;
        }
      })
        .to(welcomeHero, { duration: 1, y: '-15vh', scale: 0.65 }, "scroll")
        .to([beem, man], { duration: 0.2, autoAlpha: 0 }, "scroll")
        .to([titleChars, button], { duration: 1, yPercent: -100, autoAlpha: 0 }, "scroll")
        .to(swipeButton, { duration: 0.5, autoAlpha: 1 }, "scroll")
        .call(buttonStroke.animate, [15, 1], "scroll")
        .from([slides], { duration: 1.5, autoAlpha: 0, x: "10vw" }, "scroll+=0.5"),

      hide: gsap.timeline({
        paused: true,
        defaults: { ease: "none" },
        onComplete: () => {
          startScroll();
          isSwitching = false;
        }
      })
        .to(welcomeHero, { duration: 1, y: 0, scale: 1 }, "scroll")
        .call(buttonStroke.animate, [0, 1], "scroll")
        .to(swipeButton, { duration: 0.5, autoAlpha: 0 }, "scroll")
        .to([titleChars, button], { duration: 1, autoAlpha: 1, yPercent: 0, y: 0 }, "scroll")
        .to([slides], { duration: 1, autoAlpha: 0, x: "10vw" }, "scroll")
        .to([beem, man], { duration: 0.5, autoAlpha: 1, stagger: 0.25 }),

      moveSlides: (e) => {

        if (e) {
          slidesOffset += e.deltaX * 0.75;
          slidesOffset = Math.max(-maxDistance, Math.min(slidesOffset, 0));
          slidesProgress = Math.abs(slidesOffset) * 100 / maxDistance;
        } else {
          slidesOffset = 0;
          slidesProgress = 0;
        }

        gsap.timeline()
          .to(slidesWrapper, {
            duration: 2,
            x: slidesOffset,
            rotationY: -15
          }, "swipe")
          .call(buttonStroke.animate, [slidesProgress, 1, 0.85], "swipe")
      },

      observer: Observer.create({
        target: slider,
        type: "touch,wheel,scroll,pointer",
        axis: "x",
        onEnable() {
          slider.classList.add('active');
        },
        onDisable() {
          slider.classList.remove('active');
        },
        onDragStart() {
          slider.style.cursor = 'grabbing';
        },
        onDragEnd() {
          slider.style.cursor = 'grab';
        },
        onLeft: (self) => swipeSlider.moveSlides(self),
        onRight: (self) => swipeSlider.moveSlides(self)
      })
    }

    swipeSlider.observer.disable();

    // Swipe slider section control
    ScrollTrigger.create({
      trigger: sliderSection,
      start: "top 98%",
      end: "top 2%",
      // markers: true,
      onEnter: () => {
        swipeSlider.start();
      },
      onEnterBack: () => {
        swipeSlider.play();
      },
      onLeave: () => {
        swipeSlider.pause();
      },
      onLeaveBack: () => {
        swipeSlider.stop();
      },
    });
  }

  // Sections scroll controller
  function desktopScroll() {

    const SVGparticles = new Particles({
      wrapper: particles,
      svg: [
        `${BAMBOO.assets}img/particles/homepage-particles-1.svg`,
        `${BAMBOO.assets}img/particles/homepage-particles-2.svg`
      ]
    });

    // Slider controller
    const swipeSlider = {
      start: () => {
        SVGparticles.parallaxAnimation.pause();
        swipeSlider.observer.enable();
        swipeSlider.show.play().restart();
      },

      stop() {
        SVGparticles.parallaxAnimation.pause();
        swipeSlider.observer.disable();
        swipeSlider.hide.play().restart();
        swipeSlider.moveSlides(null);
      },

      pause() {
        swipeSlider.observer.disable();
      },

      play() {
        swipeSlider.observer.enable();
      },

      show: gsap.timeline({
        paused: true,
        defaults: { ease: "sine.inOut" },
        onComplete: () => {
          startScroll();
          isSwitching = false;
          SVGparticles.parallaxAnimation.play();
        }
      })
        .to(welcomeHero, { duration: 1, xPercent: -59, yPercent: 5, scale: 0.65 }, "scroll")
        .to([beem, man], { duration: 0.2, autoAlpha: 0 }, "scroll")
        .to([titleChars, button], { duration: 1, yPercent: -100, autoAlpha: 0 }, "scroll")
        .to(swipeButton, { duration: 0.5, autoAlpha: 1 }, "scroll")
        .call(buttonStroke.animate, [15, 1], "scroll")
        .call(() => SVGparticles.expandAnimation.play(), null, "scroll")
        .from([slides], { duration: 1.5, autoAlpha: 0, x: "10vw" }, "scroll+=0.5"),

      hide: gsap.timeline({
        paused: true,
        defaults: { ease: "none" },
        onComplete: () => {
          startScroll();
          isSwitching = false;
          SVGparticles.parallaxAnimation.play();
        }
      })
        .to(welcomeHero, { duration: 1, xPercent: 0, yPercent: 0, scale: 1 }, "scroll")
        .call(buttonStroke.animate, [0, 1], "scroll")
        .call(() => SVGparticles.expandAnimation.reverse(), null, "scroll")
        .to(swipeButton, { duration: 0.5, autoAlpha: 0 }, "scroll")
        .to([titleChars, button], { duration: 1, autoAlpha: 1, yPercent: 0, y: 0 }, "scroll")
        .to([slides], { duration: 1, autoAlpha: 0, x: "10vw" }, "scroll")
        .to([beem, man], { duration: 0.5, autoAlpha: 1, stagger: 0.25 }),

      moveSlides: (e) => {

        if (e) {
          slidesOffset += e.deltaX * 0.75;
          slidesOffset = Math.max(-maxDistance, Math.min(slidesOffset, 0));
          slidesProgress = Math.abs(slidesOffset) * 100 / maxDistance;
        } else {
          slidesOffset = 0;
          slidesProgress = 0;
        }

        gsap.timeline()
          .to(slidesWrapper, {
            duration: 2,
            x: slidesOffset,
            rotationY: -15
          }, "swipe")
          .to(particlesWrapper, {
            duration: 2,
            x: slidesOffset / 5,
          }, "swipe")
          .call(buttonStroke.animate, [slidesProgress, 1, 0.85], "swipe")
      },

      observer: Observer.create({
        target: slider,
        type: "touch,pointer",
        axis: "x",
        onEnable() {
          slider.classList.add('active');
        },
        onDisable() {
          slider.classList.remove('active');
        },
        onDragStart() {
          slider.style.cursor = 'grabbing';
        },
        onDragEnd() {
          slider.style.cursor = 'grab';
        },
        onLeft: (self) => swipeSlider.moveSlides(self),
        onRight: (self) => swipeSlider.moveSlides(self)
      })
    }

    swipeSlider.observer.disable();

    // Swipe slider section control
    ScrollTrigger.create({
      trigger: sliderSection,
      start: "top 80%",
      end: "top 20%",
      // markers: true,
      onEnter: () => {
        stopScroll();
        swipeSlider.start();
      },
      onEnterBack: () => {
        swipeSlider.play();
        SVGparticles.parallaxAnimation.play();
      },
      onLeave: () => {
        swipeSlider.pause();
        SVGparticles.parallaxAnimation.pause();
      },
      onLeaveBack: () => {
        stopScroll();
        swipeSlider.stop();
      },
    });



    // sectionsObserver = Observer.create({
    //   axis: "y",
    //   type: "wheel,scroll,touch",
    //   preventDefault: true,
    //   onDown: () => {
    //     console.log('Scroll down');
    //     if (!isSwitching) {
    //       switch (activeIdx) {
    //         case 0:
    //           // activeIdx++;
    //           console.log(activeIdx);
    //           scrollToSection(sliderSection, 1);
    //           break;
    //         case 1:
    //           // activeIdx++;
    //           scrollToSection(normalSection, 2);
    //         // case 2:
    //         //   isSwitching = false;
    //         default:
    //           // isSwitching = false;
    //           break;
    //       }
    //     }
    //   },
    //   onUp: () => {

    //     if (!isSwitching) {
    //       switch (activeIdx) {
    //         case 1:
    //           // activeIdx--;
    //           scrollToSection(0, 0);
    //           break;
    //         case 2:
    //           // activeIdx--;
    //           scrollToSection(sliderSectionOffset, 1);
    //         default:
    //           isSwitching = false;
    //           break;
    //       }
    //     }
    //   }
    // })
  }

  // Pin welcome section
  ScrollTrigger.create({
    trigger: welcomeSection,
    pin: true,
    pinSpacing: false,
    start: "top top",
    endTrigger: normalSection
  });

}

export { animationsHomePage }
