import Swiper, { FreeMode } from "swiper";
import gsap from "gsap";

export default function homeSlider() {

  const sliders = document.querySelectorAll(".free_slider");
  if (!sliders.length) return;

  let isFetching;

  sliders.forEach((s) => initSlider(s));

  function initSlider(s) {
    const slider = new Swiper(s, {
      modules: [FreeMode],
      freeMode: true,
      slidesPerView: "auto",
      grabCursor: true,
    });

    let toggler = s.dataset.toggle;
    toggler && toggleTabs(slider, toggler);
  }

  function toggleTabs(slider, toggler) {

    const tabs = [...slider.el.closest('section').querySelector(`.${toggler}`).querySelectorAll('.tag')];

    const toggleActiveTab = (active) => {
      tabs.forEach((tab, idx) => {
        tab.classList.toggle('active', idx === active);
      });
    }

    tabs.length && tabs.forEach((tab, idx) => {
      let cat = tab.dataset.cat;
      tab.addEventListener('click', () => {
        toggleActiveTab(idx);
        reloadSlider(slider, cat)
      });
    });
  }

  function reloadSlider(slider, cat) {

    isFetching = true;

    let container = slider.el.querySelector('[data-posts-container]');
    let slides = slider.slides;

    // Send request
    const sendAJAX = async () => {

      let data = new FormData();
      data.append('action', 'getVacanciesAJAX');
      data.append('cat_id', cat);
      data.append('per_page', 3);

      let response = await fetch(BAMBOO.ajaxUrl, {
        method: "POST",
        credentials: 'same-origin',
        body: data
      });

      // Get response
      if (response.ok) {

        container.innerHTML = await response.text();
        let newSlides = container.querySelectorAll('.swiper-slide');

        gsap.timeline({
          onComplete() {
            slider.update();
            isFetching = false;
          }
        })
          .set(newSlides, { autoAlpha: 0 })
          .set(slider.el, { autoAlpha: 1 })
          .to(newSlides, { duration: 0.3, autoAlpha: 1, stagger: 0.05 });

      } else {
        console.log(response.status);
        isFetching = false;
      }
    }

    gsap.timeline({ onComplete: sendAJAX })
      .to(slides, { duration: 0.3, autoAlpha: 0, stagger: { each: 0.05, from: "end" } })
      .set(slider.el, { autoAlpha: 0 });
  }
}
