import gsap from "gsap";
import SVGPathCommander from 'svg-path-commander';

const onhoverAnimations = () => {

  const buttons = [...document.querySelectorAll('[data-animate-stroke]')];

  const animateButtons = (button) => {

    const svg = button.querySelector('svg');
    const path = svg.querySelector('.animated-stroke');
    const pathLength = SVGPathCommander.getTotalLength(path.getAttribute('d'));

    const tl = gsap.timeline({ paused: true, defaults: { ease: "none" } })
      .set(path, { strokeDasharray: pathLength, strokeDashoffset: pathLength })
      .to(path, { duration: 0.5, strokeDasharray: `${pathLength} ${pathLength}` })
      .to(path, { duration: 0.5, strokeDashoffset: 0 })

    button.addEventListener('mouseenter', () => {
      tl.play();
    })

    button.addEventListener('mouseleave', () => {
      tl.reverse();
    })
  }

  buttons.length && buttons.forEach(b => animateButtons(b));
}


export { onhoverAnimations }