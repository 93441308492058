import { gsap, ScrollTrigger, ScrollToPlugin, Observer } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Observer);
import SVGPathCommander from 'svg-path-commander';

class StrokeAnimator {

  constructor(el) {
    const svg = el.querySelector('svg');
    this.path = svg.querySelector('.animated-stroke');
    this.pathLength = SVGPathCommander.getTotalLength(this.path.getAttribute('d'));
    gsap.set(this.path, { strokeDasharray: this.pathLength, strokeDashoffset: this.pathLength });
  }

  animate = (progress, duration) => {
    this.strokeOffset = this.pathLength - (Math.abs(progress) * this.pathLength / 100);
    gsap.to(this.path, { duration: duration, strokeDashoffset: this.strokeOffset })
  }
}


export { StrokeAnimator }