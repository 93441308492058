import Swiper, { FreeMode } from "swiper";

export default function teamSlider() {
  const slider = document.querySelector(".team_slider");
  if (!slider) return;
  const isMob = window.innerWidth <= 1024;

  function initSlider(s) {
    let idx = 0;
    let ss;

    const onHover = (idx) => {
      ss && ss.forEach((s, i) => s.classList.toggle("hovered", i == idx));
    };
    const slider = new Swiper(s, {
      modules: [FreeMode],
      // freeMode: !isMob,
      slidesPerView: "auto",
      slideActiveClass: "active",
      grabCursor: true,
      slideToClickedSlide: true,
      on: {
        init: (e) => {
          ss = e.slides;
          onHover(idx);

          !isMob &&
            ss.forEach((s, isx) => {
              let i = s.querySelector(".icon");
              i.addEventListener("mouseenter", (x) => onHover(isx));
              i.addEventListener("mouseleave", (x) => onHover(idx));
            });
        },
        slideChange: (e) => {
          idx = e.activeIndex;
          onHover(idx);
        },
      },
    });
  }
  initSlider(slider);
}
