import Swiper from "swiper";

export default function designSlider() {
  const slider = document.querySelector(".design_slider");
  if (!slider) return;

  function initSlider(s) {
    const slider = new Swiper(s, {
      slidesPerView: "auto",
      // grabCursor: true,
      slideToClickedSlide: true,
      speed: 1000,
      effect: "coverflow",
    });
  }
  initSlider(slider);
}
